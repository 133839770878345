/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
export const routes: Routes = [
  // eslint-disable-next-line @typescript-eslint/typedef
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(({PagesModule }) => PagesModule) },
  // eslint-disable-next-line @typescript-eslint/typedef
  { path: 'login', loadChildren: () => import('./auth/auth.module').then(({AuthModule}) => AuthModule) },
  { path: '', pathMatch: 'full', redirectTo: 'login' }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [],

})
export class AppRoutingModule { }
